@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@300;600&family=Nova+Square&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Readex+Pro:wght@200;300;400;500;600;700&family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Gluten:wght@300;600&family=Nova+Square&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Readex+Pro:wght@200;300;400;500;600;700&family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html{
    font-family: 'Raleway', sans-serif;
  }
}
